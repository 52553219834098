import React, {FC, ReactNode} from "react";
import imagePng from "images/hero-right2.png";
import HeroSearchForm, {
    SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroArchivePageProps {
    className?: string;
    listingType?: ReactNode;
    currentPage: "Stays" | "Experiences" | "Cars" | "Flights";
    currentTab: SearchTab;
    rightImage?: string;
    startDate: Date | null;
    endDate: Date | null;
    onChangeDate: (dates: [Date | null, Date | null]) => void;
    lang: boolean | true
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
                                                                     className = "",
                                                                     listingType,
                                                                     currentPage,
                                                                     currentTab,
                                                                     rightImage = imagePng,
                                                                     startDate,
                                                                     endDate,
                                                                     onChangeDate,
                                                                     lang
                                                                 }) => {
    return (
        <div dir={lang ? "ltr" : "rtl"}
             className={`nc-SectionHeroArchivePage flex flex-col relative ${className}`}
             data-nc-id="SectionHeroArchivePage"
        >
            <div className="flex flex-col lg:flex-row lg:items-center">
                <div
                    className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 lg:space-y-10 pb-14 lg:pb-64 xl:pb-80 xl:pr-14 lg:mr-10 xl:mr-0">
                    <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
                        {
                            lang
                                ?
                                "ELNASR CENTER"
                                :
                                "سنتر النصر"
                        }
                    </h2>

                    <div className="flex items-center text-base md:text-lg text-neutral-500 dark:text-neutral-400">
                        <i className="text-2xl las la-map-marked"></i>
                        <span className="ml-2.5">
                            <a href="https://maps.app.goo.gl/McTaw92TZw8W22EW6">
                                {
                                    lang
                                        ?
                                        "find out our location on map"
                                        :
                                        "موقعنا علي الخريطة"
                                }
                            </a>
                        </span>
                        <span className="mx-5"></span>
                        <i className="text-2xl las la-hourglass"></i>
                        <span className="ml-2.5">
                            {
                                lang
                                    ?
                                    "09:00.AM 04:00.PM"
                                    :
                                    "09:00 صباحا حتي 04:00 مساءا"
                            }
                        </span>
                    </div>
                </div>
                <div className="flex-grow">
                    <img className="w-full" src={rightImage} alt="hero"/>
                </div>
            </div>

            <div className="lg:flow-root w-full">
                <div className="z-10 lg:-mt-40 xl:-mt-56 w-full">
                    <HeroSearchForm currentPage={currentPage} currentTab={currentTab} startDate={startDate}
                                    endDate={endDate} onChangeDate={onChangeDate}/>
                </div>
            </div>
        </div>
    );
};

export default SectionHeroArchivePage;
