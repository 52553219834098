import Logo from "shared/Logo/Logo";
import React from "react";


const Footer: React.FC = () => {
    return (
        <div className="nc-Footer relative py-4 lg:py-4 border-t border-neutral-200 dark:border-neutral-700">
            <div
                className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
                <div className="col-span-2 md:col-span-1">
                    <Logo/>
                </div>
                <div className="col-span-2 md:col-span-1" style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    ©ELNASR CENTER 2023
                </div>
            </div>
        </div>
    );
};

export default Footer;
